import GenericHeader from "../../components/GenericHeader";
import { PRIMARY, SECONDARY } from "../../components/Colors";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ScoresTable from "../../components/Tables";
import ScoresTableVs from "../../components/Tables copy";
export default function Scores({ setDivHeight, data }) {
  const navigate = useNavigate();
  const history = useLocation();
  useEffect(() => {
    document.querySelector("#some-container").scrollIntoView();
  }, [history]);

  return (
    <div style={{ flex: 1 }}>
      <GenericHeader title={"Games"} setDivHeight={setDivHeight} />
      <div id={"some-container"}>
        <div
          style={{
            backgroundColor: PRIMARY,
            height: 100,
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <h3 className="headers" style={{ color: SECONDARY, textAlign: "center" }}>
            IEEE Region 8 Robot Championship Malta {data.year}
          </h3>
        </div>
        {/*<img src={logo} alt="Logo" width={"100%"} height={100} />*/}
        <div className="container">
          <p>
            The 4 games and Rules and regulations can be accessed from <a href={data.rulesRegulationsLink}>here</a>
          </p>
          <ScoresTable appData={data} headings={["id", "Overall Place", "Total Points"]} header={"Overall Place"} title={"Overall Place"} />
          <ScoresTable appData={data} headings={["id", "Hanging Hazard Final", "R7 Points"]} header={"Hanging Hazard Final"} title={"Hanging Hazard Final"} />
          <ScoresTable appData={data} headings={["id", "Hanging Hazard", "R6 Points"]} header={"Hanging Hazard Runner Up"} title={"Hanging Hazard"} />
          <ScoresTable appData={data} headings={["id", "Weight in line R3", "R5 Points"]} header={"Weight in line Round 3"} title={"Weight in line R3"} />
          <ScoresTable appData={data} headings={["id", "Weight in line R2", "R4 Points"]} header={"Weight in line Round 2"} title={"Weight in line R2"} />
          <ScoresTable appData={data} headings={["id", "Weight in line", "R3 Points"]} header={"Weight in line"} title={"Weight in line"} />
          <ScoresTable appData={data} headings={["id", "Climate Chaos", "R2 Points"]} header={"Climate Chaos"} title={"Climate Chaos"} />
          <ScoresTable appData={data} headings={["id", "Time To Fall", "R1 Points"]} header={"Time To Fall"} title={"Time To Fall"} />
          {/*<GenericTable*/}
          {/*  headers={["Place", "Team", "Points"]}*/}
          {/*  //   data={[{ Place: "1st", Team: "team1", Points: 12 }]}*/}
          {/*/>*/}
          {Object.keys(data.scores).map((d, i) => {
            const cont = data.scores[d];
            return (
              <div>
                <h3 className="headers">{cont.title}</h3>
                <p>
                  {cont.content}
                  {cont.link && <a href={cont.link}>here</a>}
                </p>
                {cont.photoPath && <img src={require(`../../images/${cont.photoPath}`)} alt="location photo" />}
              </div>
            );
          })}
          <h3 className="headers">{"The 4 Games"}</h3>

          {Object.keys(data.games).map((d, i) => {
            const cont = data.games[d];
            return (
              <div
                style={{
                  backgroundColor: i % 2 === 0 ? SECONDARY : "white",
                  display: "flex",
                }}
                onClick={() => {
                  navigate("/2024/game", { state: { game: cont } });
                }}
              >
                <div
                  style={{ paddingLeft: 10 }}
                  onClick={() => {
                    navigate("/2024/game", { state: { game: cont } });
                  }}
                >
                  <h3 className="headers">{cont.name}</h3>
                  <p>
                    <b>Objective: </b>
                    {cont.objective}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
