import GenericTable from "./GenericTable";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { PRIMARY } from "./Colors";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { getDatabase, ref, onValue } from "firebase/database";
import { useState } from "react";
import app from "../screens/firebase";

import { useNavigate } from "react-router-dom";
export default function ScoresTable({ appData, showTop, headings, header, title = "Overall Place" }) {
  const headers = ["Place", "Robot", "Points"];
  const navigate = useNavigate();
  const year = window.location.pathname.split("/")[1] || 2024;
  const [data, setData] = useState([]);
  const db = getDatabase();
  const checkData = (d) => {
    let temp = [];
    console.log(d, data);
    Object.keys(d).map((i) => {
      console.log(d[i], data[i]);

      if (!data[i] || JSON.stringify(data[i]) !== JSON.stringify(d[i])) {
        temp[i] = d[i];
      }
    });
    if (temp && Object.keys(temp).length > 0) {
      let tenth = d["10th"];
      delete d["10th"];
      d["10th"] = tenth;
      if (d["11th"]) {
        let elevent = d["11th"];
        delete d["11th"];
        d["11th"] = elevent;
        let twelft = d["12th"];
        delete d["12th"];
        d["12th"] = twelft;
      }
      setData(d);
    }
  };
  const starCountRef = ref(db, appData.dbKey + "/AppPoints");
  onValue(starCountRef, (snapshot) => {
    const data = snapshot.val();
    console.log("changes", data);
    checkData(data);
  });

  if (data && ((data["1st"] && data["1st"][title]) || (data["3rd"] && data["3rd"][title])))
    return (
      <div>
        {header && <h3 className="headers">{header}</h3>}
        <div onClick={() => navigate(`/${year}/games`)}>
          <TableContainer>
            <Table sx={{ width: "100%" }} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow style={{ backgroundColor: PRIMARY }}>
                  {headers.map((h) => {
                    return <TableCell style={{ color: "white" }}>{h}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {data &&
                  Object.keys(data).map((row, i) => {
                    if (showTop && i > 2) return false;
                    if (data[row][headings[1]] && data[row][headings[1]] != "#REF!" && data[row][headings[2]] != "#REF!")
                      return (
                        <TableRow
                          key={i}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {headings.map((h) => {
                            return <TableCell>{data[row][h]}</TableCell>;
                          })}
                        </TableRow>
                      );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    );
  else return null;
}
